import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFeedIndex = lazy(
  () => import("./feed-index-CgdZSriZ.js").then((module) => ({
    default: module.FeedIndex
  }))
);
function FeedIndex({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFeedIndex, { ...props });
}
const useFeedIndexContent = () => {
  return useMemo(
    () => ({
      feedIndex: {
        component: FeedIndex
      }
    }),
    []
  );
};
export {
  LazyFeedIndex as FeedIndex,
  useFeedIndexContent
};
